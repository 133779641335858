import {ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {startWith} from 'rxjs/operators';
import {DisposalOperationCode, DisposalType, RetentionTriggerTypeCode} from '|api/commons';
import {
  CodebookService,
  DisposalScheduleOption,
  disposalSchedulesToOptions,
  disposalSchedulesWithValidDate,
  enumToOptions, getExternalRetentionTriggersOptions,
  IczFormGroup,
  IczOnChanges,
  IczSimpleChanges,
  IczValidators,
  isExternalRetentionTriggerType,
  LoadingIndicatorService,
  locateOptionByValue,
  Option
} from '|shared';


@Component({
  selector: 'icz-disposal-schedule-with-retention-trigger-form-fragment',
  templateUrl: './disposal-schedule-with-retention-trigger-form-fragment.component.html',
  styleUrls: ['./disposal-schedule-with-retention-trigger-form-fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisposalScheduleWithRetentionTriggerFormFragmentComponent implements OnInit, IczOnChanges {

  protected loadingService = inject(LoadingIndicatorService);
  private codebookService = inject(CodebookService);
  private destroyRef = inject(DestroyRef);

  @Input({required: true})
  form!: IczFormGroup;
  @Input()
  withVisibleDisposalYear = false;
  @Input()
  isDisposalYearRequired = false; // effective only if withEditableDisposalYear is TRUE
  @Input()
  allowedDisposalScheduleIds: Nullable<number[]>;

  allDisposalScheduleOptions: DisposalScheduleOption[] = [];
  disposalScheduleOptions: DisposalScheduleOption[] = [];
  retentionTriggerTypeOptions: Option[] = [];

  disposalOperationOptions = enumToOptions('disposalOperationCode', DisposalOperationCode);

  externalRetentionTriggerOptions$ = getExternalRetentionTriggersOptions(this.codebookService);

  get selectedDisposalScheduleOption() {
    return locateOptionByValue(this.allDisposalScheduleOptions, this.form.get('disposalScheduleId')!.value);
  }

  get applicableDisposalType(): Nullable<DisposalType> {
    return this.selectedDisposalScheduleOption?.data?.disposalType;
  }

  get applicableDisposalYear(): Nullable<number> {
    return this.selectedDisposalScheduleOption?.data?.disposalYear;
  }

  get isRetentionTriggerTypeExternal() {
    return isExternalRetentionTriggerType(this.selectedDisposalScheduleOption?.data?.retentionTriggerTypeCode);
  }

  readonly DisposalType = DisposalType;

  ngOnInit(): void {
    this.retentionTriggerTypeOptions = enumToOptions('retentionTriggerTypeCode', RetentionTriggerTypeCode);

    this.loadingService.doLoading(
      this.codebookService.disposalSchedules(),
      this
    ).pipe(
      disposalSchedulesWithValidDate,
      disposalSchedulesToOptions,
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(disposalScheduleOptions => {
      this.allDisposalScheduleOptions = disposalScheduleOptions;
      this.disposalScheduleOptions = disposalScheduleOptions;
      this.filterOptionsByAllowedDisposalSchedules();

      const disposalScheduleControl = this.form.get('disposalScheduleId')!;

      disposalScheduleControl.valueChanges.pipe(
        startWith(null),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(_ => {
        const disposalYearControl = this.form.get('disposalYear')!;

        if (this.isDisposalYearRequired) {
          disposalYearControl.addValidators([IczValidators.required()]);
        }
        else {
          disposalYearControl.clearValidators();
        }

        disposalYearControl.updateValueAndValidity();

        if (this.applicableDisposalYear) {
          disposalYearControl.setValue(this.applicableDisposalYear);
          disposalYearControl.disable();
        }
        else {
          disposalYearControl.setValue(null);
          disposalYearControl.enable();
        }
      });
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.allowedDisposalScheduleIds && changes.allowedDisposalScheduleIds.currentValue) {
      this.filterOptionsByAllowedDisposalSchedules();
    }
  }

  filterOptionsByAllowedDisposalSchedules() {
    if (this.allowedDisposalScheduleIds?.length && this.allDisposalScheduleOptions?.length) {
      this.disposalScheduleOptions = this.allDisposalScheduleOptions.filter(dso => this.allowedDisposalScheduleIds!.includes(dso.value));
    }
  }

}
