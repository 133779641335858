<div [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async">
  <icz-form-autocomplete
    class="grow"
    formControlName="disposalScheduleId"
    label="Skartační režim"
    searchTermInputPrefix="Název"
    [options]="disposalScheduleOptions"
  ></icz-form-autocomplete>

  @if (applicableDisposalType === DisposalType.TRIGGER_EVENT_WITH_PERIOD) {
    <icz-form-autocomplete
      class="grow"
      label="Skartační operace"
      [fieldDisabled]="true"
      [options]="disposalOperationOptions"
      [value]="selectedDisposalScheduleOption?.data?.disposalOperationCode"
    ></icz-form-autocomplete>
    <icz-form-autocomplete
      class="grow"
      [fieldDisabled]="true"
      [value]="selectedDisposalScheduleOption?.data?.retentionTriggerTypeCode"
      label="Spouštěcí událost"
      [options]="retentionTriggerTypeOptions"
    ></icz-form-autocomplete>
    @if (isRetentionTriggerTypeExternal) {
      <icz-form-autocomplete
        class="grow"
        formControlName="externalRetentionTriggerId"
        label="Externí spouštěcí událost"
        [options]="externalRetentionTriggerOptions$ | async"
      ></icz-form-autocomplete>
    }
    <icz-form-field
      class="grow"
      label="Skartační lhůta"
      [fieldDisabled]="true"
      [value]="$any(selectedDisposalScheduleOption?.data?.retentionPeriod)"
    ></icz-form-field>
  }

  @if (withVisibleDisposalYear && applicableDisposalType === DisposalType.FIXED_DISPOSAL_YEAR) {
    <icz-form-field
      class="grow"
      label="Rok vyřazení"
      formControlName="disposalYear"
    ></icz-form-field>
  }
</div>
